import React from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <div className='footer text-center fixed-bottom text-white' style={{ backgroundColor: 'white' }}>
            <a className='footer-a' href='https://www.instagram.com/wangto.3.4/'>
                <MDBIcon fab className='bi bi-instagram px-4 py-2'/>
            </a>
            <a className='footer-a' href='https://www.wangtocollection.org/contact/'>
                <MDBIcon fab className='bi bi-mailbox2 px-4 py-2' />
            </a>
            <a className='footer-a' href='https://www.wangtocollection.org'>
                <MDBIcon fab className='bi bi-house-door px-4 py-2' />
            </a>
      <div className='footer-bottom text-center px-3 py-1' style={{ backgroundColor: 'white' }}>
        contact: info@wangtocollection.org
      </div>
      <div className='footer-bottom px-3 py-1' style={{ backgroundColor: 'white' }}>
        ©WANGTOCOLLECTION 2023
      </div>
    </div>
  );
}