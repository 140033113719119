import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import {useState} from 'react';
import React from 'react';
import Transitions from './transitions.js';

const ContactPage = () => {

  const initialState = {name: "", email: "", message: ""};
  const [eachEntry, setEachEntry] = useState(initialState);
  const {name, email, message} = eachEntry;

  const handleOnChange = e => {
    setEachEntry({...eachEntry, [e.target.name]: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetch('https://portfolio-node-mailer.vercel.app/send', {
        method: 'POST',
        body: JSON.stringify(eachEntry),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(
        (response) => (response.json())
       ).then((response)=> {
    if (response.status === 'success') {
        alert('Message Sent.');
        setEachEntry({...eachEntry, [e.target.name]: ''})//reset form
    } else if(response.status === 'fail') {
        alert('Message failed to send.')
    }
    })
}

  return (
    <div>
    <Transitions>
    <Container fluid className="mb-5 mt-3 pt-md-3">
      <div className="col-xl">
        <h1 id ="galleryTitle" className="my-3 pe-2 centerText">
          Inquiries.
        </h1>
        <center>
          <hr className="t_border my-4 py-2 ml-0"></hr>
        </center>
      </div>
    </Container>
    <Container className="formHeader py-5">
    <Form onSubmit={handleSubmit}>
      <Form.Group  controlId="formBasicName">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          onChange={handleOnChange}
          value={name}
          name="name"
          type="text" 
          placeholder="Enter Name" />
      </Form.Group>
      <Form.Group  controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control 
          onChange={handleOnChange}
          value={email}
          name="email"
          type="email" 
          placeholder="Enter Email" />
      </Form.Group>
      <Form.Group  controlId="formBasicTextField">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea" 
          rows={4}
          onChange={handleOnChange}
          value={message}
          name="message"
          type="message" 
          placeholder="Enter Message" />
      </Form.Group>
      <br></br>
      <Container>
        <Button variant="success" type="submit">Submit</Button>
      </Container>
    </Form>
    </Container>
    <br></br>
    <br></br>
    <br></br>
    </Transitions>
    </div>
  );
}

export default ContactPage;