import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navigation/Navbar.js";
import Footer from "./Components/Footer.js";
import HomePage from "./Pages/home.js";
import AboutPage from "./Pages/about.js";
import ContactPage from "./Pages/contact.js";
import GalleryPage from "./Pages/gallery.js";
import { AnimatePresence } from "framer-motion";
import {
  BrowserRouter as Router,
  withRouter,
  useLocation,
  Route,
  Routes,
  Switch,
  Link,
  Navigate
} from "react-router-dom";

const Animated = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
            <Route exact path="/about" element={<AboutPage/>} />
            <Route exact path="/" element={<HomePage/>} />
            <Route exact path="/contact" element={<ContactPage/>} />
            <Route exact path="/gallery" element={<GalleryPage/>} />
            <Route path="*" element={<h1>404 not found</h1>} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

function App () {
  return (
      <>
        <Router>
          <Navbar/>
          <Animated />
          <Footer/>
        </Router>
      </>
    );
}

export default App;
