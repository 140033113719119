import '../index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import About1 from "../Gallery/About1.png";
import { motion } from "framer-motion";

function AboutPage() {

  return (
    <div>
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
      <Container fluid className="mb-5 mt-3 pt-md-3">
        <div className="col-xl">
          <h1 id ="galleryTitle" className="my-3 pe-2  centerText">
            About. 
          </h1>
          <center>
            <hr className="t_border my-4 py-2 ml-0"></hr>
          </center>
        </div>
      </Container>
      <Container >
        <Row>
            <Col>
              <div className = "imgBox">
                <img src={About1} width="100%" height="auto" alt="Gallery1" id="image-section"/>
              </div>
            </Col>
            <Col className="about-me" style={{fontSize:"2.5w"}}>
              <Row>
              Kevin (To) Wang is a Taiwanese-Canadian collector focusing on the acquisition and appreciation of emerging young artists through the Wang To Collection. 
              The focus of this collection is to document and archive featured long-term acquisitions from emerging artists in his personal art collection 
              as well as his personal art collecting journey along the way. 
              </Row>
              <br></br>
              <Row>
              The primary focus of this collection is to support emerging artists and provide a platform to showcase their talent. Artists in this collection showcase 
              strong textural and technical fine details, originality, strong imagery and narrative in their artworks. It is again a great privilege to share 
              select works from the collection to reflect the diversity and vitality of emerging art.
              </Row>
            </Col>
        </Row>
      </Container>
      </motion.div>
    </div>
  );
};

export default AboutPage;