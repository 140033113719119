import React from "react";
import Logo from "./logo.png";
import "bootstrap/js/src/collapse.js";

const Navbar = () => {
  return (
    <nav className="navbar bg-white fixed-top navbar-expand-lg justify-content-lg-center justify-content-start py-2 flex-nowrap flex-row" >
      <a className="navbar-brand" href="/" style={{color:"black",fontSize:'35px'}}> 
        <img src={Logo} width="60%" height="60%" alt=""/>
      </a>   
      <button className="navbar-toggler navbar-toggler-right" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown">
          <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav justify-content-between mx-auto text-center">
        <li className="nav-item px-5 pt-3">
            <a className="nav-link" aria-current="page" href="/about" style={{ color:"black", fontSize:'30px', fontWeight:'bold'}}>
              ABOUT 
            </a>
          </li>
          <li className="nav-item px-5 pt-3">
            <a className="nav-link " aria-current="page" href="/gallery" style={{ color:"black", fontSize:'30px', fontWeight:'bold'}}>
              COLLECTION 
            </a>
          </li>
          <li className="nav-item px-5 pt-3">
            <a className="nav-link"  aria-current="page" href="/contact" style={{ color: 'black',fontSize:'30px', fontWeight:'bold'}}>
              CONTACT
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;