import React from "react";
import Aicon1 from "../Gallery/Aicon1.PNG";
import Brianna1 from "../Gallery/Brianna1.png";
import Brianna2 from "../Gallery/Brianna2.PNG";
import Erin1 from "../Gallery/Erin1.png";
import Erin2 from "../Gallery/Erin2.png";
import Erin3 from "../Gallery/Erin3.PNG";
import Adam1 from "../Gallery/Adam1.PNG";
import Anthony1 from "../Gallery/Anthony1.png";
import Alexa1 from "../Gallery/Alexa1.png";
import Alexis1 from "../Gallery/Alexis1.png";
import Chloe1 from "../Gallery/Chloe1.png";
import Florian1 from "../Gallery/Florian1.PNG";
import Haeryang1 from "../Gallery/Haeryang1.png";
import Paulina1 from "../Gallery/Paulina1.png";
import Paulina2 from "../Gallery/Paulina2.PNG";
import Maria1 from "../Gallery/Maria1.png";
import Sohee1 from "../Gallery/Sohee1.png";
import Gabe1 from "../Gallery/Gabe1.png";
import Gabe2 from "../Gallery/Gabe2.PNG";
import Gabe3 from "../Gallery/Gabe3.PNG";
import Gisela1 from "../Gallery/Gisela1.png";
import Hannah1 from "../Gallery/Hannah1.png";
import Hannah2 from "../Gallery/Hannah2.PNG";
import Hong1 from "../Gallery/Hong1.PNG";
import Jeff1 from "../Gallery/Jeff1.PNG";
import Juan1 from "../Gallery/Juan1.png";
import Juan2 from "../Gallery/Juan2.png";
import Julia1 from "../Gallery/Julia1.PNG";
import Kate1 from "../Gallery/Kate1.PNG";
import Kofi1 from "../Gallery/Kofi1.PNG";
import Oscar1 from "../Gallery/Oscar1.png";
import Zoe1 from "../Gallery/Zoe1.png";
import Shaoyu1 from "../Gallery/Shaoyu1.png";
import Luke1 from "../Gallery/Luke1.png";
import Kang1 from "../Gallery/Kang1.png";
import Ranald1 from "../Gallery/Ranald1.PNG";
import Mcrury1 from "../Gallery/Mcrury1.png";
import Santiago1 from "../Gallery/Santiago1.png";
import Wei1 from "../Gallery/Wei1.png";
import Wei2 from "../Gallery/Wei2.PNG";
import OTA1 from "../Gallery/OTA1.png";
import OTA2 from "../Gallery/OTA2.PNG";
import Molly1 from "../Gallery/Molly1.png";
import Caleb1 from "../Gallery/Caleb1.png";
import Crystel1 from "../Gallery/Crystel1.png";
import Gahee1 from "../Gallery/Gahee1.PNG";
import Murmure1 from "../Gallery/Murmure1.PNG";
import Noah1 from "../Gallery/Noah1.png";
import Container from 'react-bootstrap/Container';
import Transitions from './transitions.js';

const Gallery = () => {
  return (
    <div>
      <Transitions>
      <Container fluid className="mb-5 mt-3 pt-md-3">
        <div className="col-xl">
          <h1 id ="galleryTitle" className="my-3 pe-2 centerText">
            Select Works.
          </h1>
          <center>
            <hr className="t_border my-4 py-2 ml-0"></hr>
          </center>
        </div>
      </Container>
      <div className='mb-5 po_items_ho'>
      <div className='po_item'>
          <img src={Alexa1} width="100%" height="100%" alt="Gallery1"  />
          <div className='content'>
            <p>Alexa Hawksworth</p>
            <p>Interior, Night, II</p>
            <p>2021. Oil on Canvas, Oil Pastel on Board & Pine Frames</p>
            <p>37 x 49"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Erin1} width="100%" height="100%" alt="Gallery5"  />
          <div className='content'>
            <p>Erin Milez</p>
            <p>Over, Under, Over Under</p>
            <p>2021. Acrylic on Canvas.</p>
            <p>20 x 20"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Sohee1} width="100%" height="100%" alt="Gallery7"  />
          <div className='content'>
            <p>Sohee Ahn</p>
            <p>Braid Hair</p>
            <p>2022. Oil on Canvas</p>
            <p>20 x 28.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Shaoyu1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Shaoyu Chen</p>
            <p>When Life Gives You Demons</p>
            <p>2021. Acrylic on Canvas</p>
            <p>31 x 39"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Luke1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Luke Silva</p>
            <p>Coimbra Fire</p>
            <p>2021. Watercolour on Canvas</p>
            <p>20 x 24"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Kang1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Kang Jun Seok</p>
            <p>My Mate - Boy & Girl</p>
            <p>2022. Urethane paint on resin and hand-painted eyes</p>
            <p>2 x 10"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Ranald1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Ranald MacDonald</p>
            <p>As The Years Go By II</p>
            <p>2022. Chalk pastel gesso on paper</p>
            <p>17 x 21"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Adam1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Adam Linn</p>
            <p>Untitled</p>
            <p>2022. Colored pencil, crayon and watercolor on paper</p>
            <p>18 x 24"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Erin3} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Erin Milez</p>
            <p>Midnight Prayer</p>
            <p>2021. Oil and Acrylic Pumice Gel on Canvas</p>
            <p>8 x 10"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Julia1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Julia Deruvo</p>
            <p>Feeling Ready</p>
            <p>2021.Oil on Canvas</p>
            <p>18 x 21.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Kate1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Katelyn Ledford</p>
            <p>Untitled</p>
            <p>2022. Watercolor and Acrylic on COA</p>
            <p>15.5 x 8.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Haeryang1} width="100%" height="100%" alt="Gallery3"  />
          <div className='content'>
            <p>Haeryang Lee</p>
            <p>Muscles to Float Well on the Swimmimg Pool</p>
            <p>2021. Acrylic on Canvas</p>
            <p>63 x 51"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Noah1} width="100%" height="100%" alt="Gallery6"  />
          <div className='content'>
            <p>Noah Schneiderman</p>
            <p>The Unconcealed</p>
            <p>2021. Oil and Wax on Canvas </p>
            <p>40 x 30"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Oscar1} width="100%" height="100%" alt="Gallery5"  />
          <div className='content'>
            <p>Oscar Yi Hou</p>
            <p>Sayonara, Suzie Wongs, aka: Out the Opium Den</p>
            <p>2022. Hand-embellished archival pigment print</p>
            <p>23.25 x 29"</p>
          </div>
        </div>
        <div className='po_item'>
        <img src={Juan2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Juan Arango Palacios</p>
            <p>Aquel Arbolito</p>
            <p>2022. Pastel and colored pencil on paper</p>
            <p>36 x 48"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Erin2} width="100%" height="100%" alt="Gallery3"  />
          <div className='content'>
            <p>Erin Milez</p>
            <p>Momma and Child</p>
            <p>2022. Acrylic Ink and Oil on Panel</p>
            <p>9 x 12"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Alexis1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Alexis Jang</p>
            <p>Untitled</p>
            <p>2022. Oil on Paper</p>
            <p>6.5 x 9.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Hannah1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Hannah Wilson</p>
            <p>Green Shirt</p>
            <p>2022. Oil on Canvas</p>
            <p>14 x 18"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Chloe1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Chloe Mosbacher</p>
            <p>Untited (Longing)</p>
            <p>2022. oils, casein, acrylic and pumice on paper</p>
            <p>mounted to cradled wood panel</p>
            <p>12 x 16"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Anthony1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Anthony Padilla</p>
            <p>Tourists</p>
            <p>2021. Oil on Canvas</p>
            <p>11 x 14"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Jeff1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Jeff Ladoceur</p>
            <p>Untitled</p>
            <p>2021. Pencil, pen on Book Cover</p>
            <p>8 x 8"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Hannah2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Hannah Wilson</p>
            <p>Portrait with Ruff</p>
            <p>2021. Oil on Canvas</p>
            <p>8 x 10"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Paulina1} width="100%" height="100%" alt="Gallery4"  />
          <div className='content'>
          <p>Paulina Meyer</p>
            <p>Swan Song IV</p>
            <p>2022. Oil on Canvas</p>
            <p>72 x 48"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Crystel1} width="100%" height="100%" alt="Gallery7"  />
          <div className='content'>
            <p>Crystel Pereira</p>
            <p>Trouble et vert tendre</p>
            <p>2022. Oil on Canvas  </p>
            <p>30 x 40"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Maria1} width="100%" height="100%" alt="Gallery6"  />
          <div className='content'>
            <p>Maria Fragoso</p>
            <p>Lovers, II</p>
            <p>2021. Colored Pencil on Paper</p>
            <p>6 x 8"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Juan1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Juan Arango Palacios</p>
            <p>Quisiera Tener un Corazón de Piedra</p>
            <p>2022. Pastel and colored pencil on paper</p>
            <p>36 x 48"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Zoe1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Zoe McGuire</p>
            <p>Night Exit</p>
            <p>2021. Oil on Canvas</p>
            <p>10 x 10"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Santiago1} className ='center' width="85%"  height="90%" alt="Gallery1"  />
          <div className='content'>
            <p>Santiago Galeas</p>
            <p>Pink Portrait</p>
            <p>2019. Oil on Canvas.</p>
            <p>16 x 20"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={OTA2} className ='center' width="100%"  height="100%" alt="Gallery1"  />
          <div className='content'>
            <p>OTA</p>
            <p>I Wanted to Wear Tweed</p>
            <p>2022. Oil Pastel on Paper.</p>
            <p>18 x 24"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Gabe2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Gabe Cortese</p>
            <p>Shadow Fence</p>
            <p>2020. Oil on Wood Panel</p>
            <p>9 x 12"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Florian1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Florian Eymann</p>
            <p>Untitled</p>
            <p>2021. Oil on Paper</p>
            <p>9.5 x 12.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Wei2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Wei Ming Hu</p>
            <p>Mountains in the Distance</p>
            <p>2021. Painting on wood</p>
            <p>12 x 12"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Hong1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>HONG</p>
            <p>Afternoon Tea with Ken</p>
            <p>2022. Acrylic on Canvas</p>
            <p>16 x 20"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Kofi1} className ='center' width="85%"  height="90%" alt="Gallery4"  />
          <div className='content'>
            <p>Kofi Perry</p>
            <p>Northerner Entering the Library</p>
            <p>2022. Oil on Canvas.</p>
            <p>67 x 38.5"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Paulina2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Paulina Meyer</p>
            <p>Swan Song I</p>
            <p>2021. Oil on Canvas  </p>
            <p>36 x 48"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Gabe1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Gabe Cortese</p>
            <p>Chasers</p>
            <p>2020. Oil on Wood Panel</p>
            <p>5 x 7"</p>
          </div>
        </div>
        <div className='po_item'>
        <img src={Gisela1} width="100%" height="100%" alt="Gallery12"  />
          <div className='content'>
            <p>Gisela McDaniel</p>
            <p>Prutehi/Potfia</p>
            <p>2021. Print on photo rag bright white paper</p>
            <p>22 x 30"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Brianna1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Brianna Bass</p>
            <p>Night Exit</p>
            <p>2020. Acrylic on Cradled Panel </p>
            <p>12 x 12"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Mcrury1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Peter Mcrury</p>
            <p>Floating</p>
            <p>2022. Acrylic on Canvas </p>
            <p>30 x 40"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={OTA1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>OTA</p>
            <p>Unicorn</p>
            <p>2022. Oil Pastel on Paper</p>
            <p>18 x 24"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Gabe3} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Gabe Cortese</p>
            <p>Beach Memory</p>
            <p>2022. Oil on Panel</p>
            <p>5 x 7"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Brianna2} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Brianna Bass</p>
            <p>Untitled</p>
            <p>2019. acrylic on 1.5" cradled panel</p>
            <p>8 x 8"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Wei1} className ='center' width="85%"  height="90%" alt="Gallery1"  />
          <div className='content'>
            <p>Wei Ming Hu</p>
            <p>One Man's Cabin</p>
            <p>2021. Oil on Canvas.</p>
            <p>12 x 12"</p>
          </div>
        </div>
        <div className='po_item'>
          <img src={Aicon1} width="100%" height="100%" alt="Gallery8"  />
          <div className='content'>
            <p>Aicon</p>
            <p>Untitled</p>
            <p>2020. Acrylic on paper mounted on panel</p>
            <p>6 x 8"</p>
          </div>
        </div>
      </div>
      </Transitions>
    </div>
  );
};
  
export default Gallery;