import React from "react";
import TEST from "../TEST.mp4";

const Vidplayer = () => {
  return (
        <div>
          <video autoPlay loop muted
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            minHeight: "700px",
            objectFit: "fill",
          }}>
            <source src={TEST} type="video/mp4"/>
          </video>
        </div>
      );
}

export default Vidplayer;