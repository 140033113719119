import React from 'react';
import ReactDOM from 'react-dom/client';
import { motion } from "framer-motion";
import '../index.css';
import VidPlayer from "../VideoPlayer/Vidplayer.js";
import TEST from "../TEST.mp4";
import Transitions from './transitions.js';

function HomePage() {

  return (
    <>
      <div>
        <Transitions>
          <VidPlayer/>
        </Transitions>
      </div>
    </>
  );
};

export default HomePage;